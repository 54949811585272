import React from 'react';
//import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PasswordResetPage = () => (
  <Layout>
    <SEO title="Password Reset" />
    
    <p>A password reset link has been sent to your email.</p>
  </Layout>
);

export default PasswordResetPage;
